// 常量
const CONSTANT = {
  SUBMIT_MEAL_PERIOD: [
    {
      label: '早餐',
      value: 'breakfast'
    },
    {
      label: '午餐',
      value: 'lunch'
    },
    {
      label: '晚餐',
      value: 'dinner'
    },
    {
      label: '夜宵',
      value: 'night'
    }
  ]
};

export default CONSTANT;
